import { gql } from '@apollo/client'

export const INVITE_EMPLOYER_STAFF_ACCOUNTS = gql`
  mutation InviteEmployerStaffAccounts($emails: [String!]!) {
    inviteEmployerStaffAccounts(emails: $emails) {
      failed
      successful
      reactivationRequired
    }
  }
`

export const RESEND_EMPLOYER_STAFF_ACCOUNT_INVITE = gql`
  mutation resendEmployerStaffAccountInvite(
    $input: ResendEmployerStaffAccountInviteInput!
  ) {
    resendEmployerStaffAccountInvite(input: $input)
  }
`

export const EMPLOYER_STAFF_ACCOUNTS = gql`
  query EmployerStaffAccounts(
    $first: Int
    $after: String
    $searchTerm: String
    $roles: [String!]
    $statuses: [String!]
  ) {
    employerStaffAccounts(
      first: $first
      after: $after
      searchTerm: $searchTerm
      roles: $roles
      statuses: $statuses
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        node {
          id
          email
          roles
          userId
          status
          fullName
        }
      }
    }
  }
`

export const CHANGE_EMPLOYER_STAFF_ACCOUNT_PERMISSIONS = gql`
  mutation UpdateUserPermission($input: UpdateUserPermissionInput!) {
    updateUserPermission(input: $input) {
      id
      email
      roles
      userId
      status
    }
  }
`

export const DEACTIVE_EMPLOYER_STAFF_ACCOUNTS = gql`
  mutation ($input: DeactivateUserProfileInput!) {
    deactivateUserProfile(input: $input) {
      id
      status
    }
  }
`

export const ACTIVE_EMPLOYER_STAFF_ACCOUNTS = gql`
  mutation ($input: ActivateUserProfileInput!) {
    activateUserProfile(input: $input) {
      id
      status
    }
  }
`
