import React, { useContext, useState } from 'react'
import { Menu, MenuItem, Button, Grid, Box } from '@mui/material'
import { Color, Dialog, DialogWithoutAction } from 'components'
import { StaffAccountContext } from 'context/StaffAccountProvider'
import { EmployerStaffAccountStatus } from 'model/organization'

interface Props {
  selectedStaffAccountId: string
  anchorEl: any
  setAnchorEl: (_e: any) => void
  status: string
}

const StaffMenu = ({
  selectedStaffAccountId,
  anchorEl,
  setAnchorEl,
  status
}: Props) => {
  const [open, setOpen] = useState(false)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const { resendInvite, deactiveStaff } = useContext(StaffAccountContext)

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        autoFocus
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(undefined)
        }}
        PaperProps={{
          sx: { width: 296 }
        }}
      >
        <MenuItem
          sx={{
            backgroundColor: Color.error,
            color: Color.white,
            ':hover': { backgroundColor: Color.error, color: Color.white }
          }}
          onClick={() => {
            setAnchorEl(undefined)
            setIsConfirmDialogOpen(true)
          }}
        >
          Deactivate user
        </MenuItem>
        {status === EmployerStaffAccountStatus.PENDING && (
          <MenuItem
            onClick={() => {
              setAnchorEl(undefined)
              setOpen(true)
            }}
          >
            Resend invite
          </MenuItem>
        )}
      </Menu>
      <Dialog
        open={open}
        title='Resend invite?'
        confirmProps={{
          text: 'Send',
          onClick: async () => {
            await resendInvite({
              variables: {
                input: { employerStaffAccountId: selectedStaffAccountId }
              }
            })
            setOpen(false)
          }
        }}
        cancelProps={{
          onClick: () => {
            setOpen(false)
          }
        }}
        onClose={() => {
          setOpen(false)
        }}
        banner={{
          content:
            "New staff are invited as Basic Users and don't count against your seats. You can edit roles once they've created an account.",
          type: 'info',
          multiline: true
        }}
        content='It may take a few hours for invitations to arrive.'
      />
      <DialogWithoutAction
        open={isConfirmDialogOpen}
        title='Confirm deactivation'
        onClose={() => {
          setIsConfirmDialogOpen(false)
        }}
        content='Are you sure you want to deactivate this user?'
      >
        <Box sx={{ marginLeft: 'auto', width: 200, marginTop: 2 }}>
          <Button
            variant='contained'
            sx={{
              backgroundColor: Color.grey100,
              color: Color.black,
              ':hover': { backgroundColor: Color.grey200 }
            }}
            onClick={() => {
              setIsConfirmDialogOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            sx={{
              marginLeft: 1,
              backgroundColor: Color.error,
              ':hover': { backgroundColor: Color.error },
              color: Color.white
            }}
            onClick={() => {
              deactiveStaff(selectedStaffAccountId)
              setIsConfirmDialogOpen(false)
            }}
          >
            Deactivate
          </Button>
        </Box>
      </DialogWithoutAction>
    </>
  )
}

export default StaffMenu
