import { useQuery } from '@apollo/client'
import { useMutation } from 'gql/hooks'
import { PaginationState } from 'model'
import { useEffect, useReducer, useContext } from 'react'
import { AlertContext } from 'context/AlertContext'
import {
  INITIAL_STAFF_MEMBER_FILTER,
  INITIA_PAGINATION_STATE
} from './constants'
import {
  ACTIVE_EMPLOYER_STAFF_ACCOUNTS,
  CHANGE_EMPLOYER_STAFF_ACCOUNT_PERMISSIONS,
  DEACTIVE_EMPLOYER_STAFF_ACCOUNTS,
  EMPLOYER_STAFF_ACCOUNTS,
  INVITE_EMPLOYER_STAFF_ACCOUNTS,
  RESEND_EMPLOYER_STAFF_ACCOUNT_INVITE
} from './gql'

const useStaffAccount = () => {
  const { displayAlertMessage } = useContext(AlertContext)
  const [filterState, dispatchFilter] = useReducer(
    (formState: any, action: any) => {
      return {
        ...formState,
        [action.index]: action.value
      }
    },
    INITIAL_STAFF_MEMBER_FILTER
  )
  const [paginationState, dispatchPaginationState] = useReducer(
    (state: PaginationState, action: any) => {
      switch (action.type) {
        case 'rowsPerPageChange':
          return {
            ...state,
            rowsPerPage: action.value,
            currentPage: 0,
            cursor: undefined
          }
        case 'reset':
          return {
            ...state,
            cursor: undefined,
            currentPage: 0
          }
        default:
          return { ...state, [action.index]: action.value }
      }
    },
    INITIA_PAGINATION_STATE
  )

  const {
    data: { employerStaffAccounts } = {
      employerStaffAccounts: { edges: [] }
    },
    loading,
    fetchMore,
    refetch
  } = useQuery(EMPLOYER_STAFF_ACCOUNTS, {
    variables: {
      first: paginationState.rowsPerPage,
      searchTerm: filterState.name,
      roles: filterState.roles,
      statuses: filterState.statuses
    }
  })

  useEffect(() => {
    refetch({
      first: paginationState.rowsPerPage,
      searchTerm: filterState.name,
      roles: filterState.roles,
      statuses: filterState.statuses
    })
    dispatchPaginationState({
      type: 'reset'
    })
  }, [
    filterState.name,
    filterState.roles,
    filterState.statuses,
    paginationState.rowsPerPage,
    refetch
  ])

  useEffect(() => {
    if (!employerStaffAccounts.edges.length) return

    const { endCursor } = employerStaffAccounts.pageInfo
    const { currentPage, rowsPerPage } = paginationState

    if (currentPage * rowsPerPage >= employerStaffAccounts.edges.length) {
      fetchMore({
        variables: {
          after: endCursor
        }
      })
    }
  }, [
    employerStaffAccounts,
    employerStaffAccounts.edges.length,
    employerStaffAccounts.length,
    employerStaffAccounts.pageInfo,
    fetchMore,
    paginationState,
    paginationState.currentPage,
    paginationState.rowsPerPage
  ])

  const [changePermission] = useMutation(
    CHANGE_EMPLOYER_STAFF_ACCOUNT_PERMISSIONS
  )

  const [resendInvite] = useMutation(RESEND_EMPLOYER_STAFF_ACCOUNT_INVITE, {
    successNotification: 'Invite resent'
  })

  const [inviteStaff] = useMutation(INVITE_EMPLOYER_STAFF_ACCOUNTS, {})
  const [handleDeactivateStaff] = useMutation(
    DEACTIVE_EMPLOYER_STAFF_ACCOUNTS,
    {}
  )
  const [handleActivateStaff] = useMutation(ACTIVE_EMPLOYER_STAFF_ACCOUNTS, {})

  const deactiveStaff = async (profileId: string) => {
    const result = await handleDeactivateStaff({
      variables: { input: { userProfileId: profileId } }
    })
    if (result && result.data) {
      displayAlertMessage('User has been deactivated')
    } else if (result) {
      displayAlertMessage('You are unauthorized to perform this action')
    } else {
      displayAlertMessage('We encounted an error, please try again later')
    }
    const deactivateUserProfileId = result?.data?.deactivateUserProfile?.id
    if (deactivateUserProfileId) {
      refetch({
        first: paginationState.rowsPerPage,
        searchTerm: filterState.name,
        roles: filterState.roles,
        statuses: filterState.statuses
      })
    }
  }

  const activateStaff = async (email: string) => {
    const result = await handleActivateStaff({
      variables: { input: { email } }
    })
    const activateUserProfileId = result?.data?.activateUserProfile?.id
    if (activateUserProfileId) {
      refetch({
        first: paginationState.rowsPerPage,
        searchTerm: filterState.name,
        roles: filterState.roles,
        statuses: filterState.statuses
      })
    }
    return email
  }

  return {
    inviteStaff,
    resendInvite,
    employerStaffAccounts,
    loading,
    paginationState,
    filterState,
    dispatchFilter,
    dispatchPaginationState,
    fetchMore,
    changePermission,
    deactiveStaff,
    activateStaff
  }
}

export default useStaffAccount
